class ColorTheme {
    static get() {
        return {
            primary: "#00BFA6",
            secondary: "#009480",
            background: "#f2f2f2",
            background_inverted: "#121212",
            highlight: "#d6d6d6",
            dark_highlight: "#cccccc",
            text: "#000000",
            green: "#2ecc71",
            red: "#e74c3c",
            orange: "#f39c12",
            purple: "#9b59b6",
            blue: "#3498db",
        }
    }
}

export default ColorTheme;