class Globals {
    static COOKIE_TOKEN_NAME = "kslAuthToken";
    static COOKIE_TOKEN_EXP_DURATION = 5;
    static BACKUP_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJVQi1PVFAiLCJpYXQiOjE3MzI3MTA2MzAsImV4cCI6MTczMjk3MDEzMCwia2V5Ijoib21hIiwidXNlcl9pZCI6IjI5OWI3MjU5LTQyYjYtMTFlZi1hODkyLTA2ZjBjNjY0NzgzNiIsImVtYWlsIjoia3NsMS50ZXN0c3R1ZGVudEBzdHVkZW50cy51bmliZS5jaCIsIm1vZGUiOiJ2aXNpdG9yIn0.npMXvHCs8MSq4wvZd5AfqAYwDtC_s9b9odzgZrDob7k";

    static SESSION_STORAGE_LISTS = "lists";
    static SESSION_STORAGE_USERS = "users-";
    static SESSION_STORAGE_RENDER_LISTS = "renderLists";
    static SESSION_STORAGE_RENDER_APPOINTMENT = "renderAppointemnt-";


    static SCREEN_HOME = "/";
    static SCREEN_LIST_DAY_APPOINTMENTS = "/listdayappointments";
}

export default Globals;