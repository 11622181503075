import { ResponsivePie } from "@nivo/pie";

import { PieChartHeaderText, GridElement } from "../util/Styles";

const colors_3 = { 'anwesend': '#00AA00', 'abwesend': '#EE0000', 'noshow': '#AAAAAA'};
const colors_2 = { 'anwesend': '#00AA00', 'abwesend': '#EE0000'};


const PieChart = ({data, onClick, key, onlyTwo=false}) => {
    let sum = 0;
    let listData = data.listData;
    if (listData.length === 3) {
        sum = listData[0].value + listData[1].value + listData[2].value;
    }else if (listData.length === 2) {
        sum = listData[0].value + listData[1].value;
    }

    const getColor = bar => onlyTwo ? colors_2[bar.id] : colors_3[bar.id];

    return(
        <GridElement 
            hover={data.clickable ? true : false}
            key={key}
            disabled={!data.clickable}
            onClick={() => {onClick(data.permid)}}
        >
            <PieChartHeaderText onlyTwo={onlyTwo} tooLong={data.title.length >= 60}>
                {data.title}    
            </PieChartHeaderText>
            <p style={{fontSize: 18, fontWeight: 100, color: '#333333',}}>{"Angemeldete User: "+sum}</p>
            <div style={{height: 240, display: 'flex'}}>
                <ResponsivePie
                    colors={getColor}
                    margin={{ bottom: 30, right: 200 }}
                    data={listData}
                    innerRadius={0.5}
                    //cornerRadius={5} //might look good
                    //borderWidth={0.5} //helps destinguish sections
                    enableArcLinkLabels={false}
                    legends={[
                        {
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 80,
                            translateY: 0,
                            itemsSpacing: 5,
                            itemWidth: 80,
                            itemHeight: 22,
                            itemTextColor: '#333333',
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ],
                        }
                    ]}
                    theme={{
                        legends: {
                            text: {
                                fontSize: 16,
                                fontWeight: 100,
                                color: '#333333',
                            }
                        }
                    }}
                />
            </div>
        </GridElement>
    );
}

export default PieChart;