import AppLogo from "../assets/img/PresenceAssistLogo.png"

import { HeaderText } from "../util/Styles";

const Header = ({title="Dashboard"}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#009480', height: 100}}>
            <img style={{position: 'absolute', width: 346, height: 100}} src={AppLogo} alt="App logo"/>
            <HeaderText>
                {title}
            </HeaderText>
        </div>
    );
}

export default Header