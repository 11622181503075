import styled from "styled-components";

export const PieChartHeaderText = styled.p(
    props => ({
        fontSize: props.onlyTwo ?
            props.tooLong ?
                18
                :
                22
            :
            27,
        fontWeight: 100,
        color: '#333333',
        overflowWrap: 'break-word'
        
    })
);

export const GridElement = styled.button(
    props =>({
        height: 400,
        width: 500,
        margin: 10,
        backgroundColor: '#DDDDDD33',
        textAlign: 'center',
        borderWidth: 1,
        borderColor: '#00000088',
        borderRadius: 10,
        transition: 'borderWidth 0.1s, transform 0.3s ease',
        '&:hover': props.hover ? {
        borderWidth: 2, 
        transform: 'scale(1.01)',
        } : '',
    })
);

export const HeaderText = styled.p({
    fontSize: 40,
    fontWeight: 100,
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
});