import loadingSpinner from "../assets/img/loadingSpinner.svg"

const LoadingSpinner  = ({loadingText}) => {
    return(
        <div style={{position: 'absolute', zIndex: 2, top: '50%', left: '50%', textAlign: 'center', transform: 'translate(-50%, -50%)'}}>
            <img src={loadingSpinner} />
            <p style={{marginTop: -20, fontSize: 25, fontWeight: 200}}>{loadingText}</p>
        </div>
    );
}

export default LoadingSpinner;