import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserLists from './screens/UserLists';
import ListDayAppointments from './screens/ListDayAppointments';
import Globals from './Globals';


function App() {
  return (
    <Router>
      <Routes>
        <Route path={Globals.SCREEN_HOME} element={<UserLists />} />
        <Route path={Globals.SCREEN_LIST_DAY_APPOINTMENTS} element={<ListDayAppointments />} />
      </Routes>
    </Router>
  );
}

export default App;