import styled from "styled-components";
import Cookies from 'js-cookie';


import Fetcher from '../util/Fetcher';
import ColorTheme from "../util/ColorTheme";
import { useState } from "react";
import Globals from "../Globals";

const fetcher = new Fetcher();

const Login = ({loginDone}) => {
    const [verify, setVerify] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [userVerify, setUserVerify] = useState("");
    const [buttonText, setButtonText] = useState("Login");

    const sendLoginInfo = async () => {
        let resp = await fetcher.authUser(userInput);
        console.log("Resp: "+resp);
        setVerify(resp);
        if (resp) {
            setButtonText("Verifizieren");
        }
    }

    const sendVerifyToken = async () => {
        let token = await fetcher.verifyUser(userInput, userVerify);
        if (token) {
            Cookies.set(Globals.COOKIE_TOKEN_NAME, token, {expires: 1, path: '', sameSite: 'strict'});
            loginDone();
        }else {
            alert("Bestätigungscode falsch");
        }
    }

    const performButtonClick = () => {
        if (buttonText === "Login")
            sendLoginInfo();
        else
            sendVerifyToken();
    }

    return(
        <LoginContainer>
            <Text style={{fontSize: 30, fontWeight: 200, textAlign: 'center'}}>Login</Text>
            <InputContainer>
                <Text>Email/Tel-Nr.</Text>
                <TextInput onChange={(evt) => setUserInput(evt.target.value)}/>
            </InputContainer>

            <InputContainer>
                <Text>Bestätigungscode</Text>
                <TextInput disabled={buttonText === "Login" ? "disabled" : ""} onChange={evt => setUserVerify(evt.target.value)} />
            </InputContainer>
            <Button onClick={() => {performButtonClick()}}>
                <p>{buttonText}</p>
            </Button>
        </LoginContainer>
    );
}

const LoginContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 200,
    height: 300,
    width: 500,
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: ColorTheme.get().primary
});

const InputContainer = styled.div({
    display : 'flex',
    flexDirection: 'row',
    margin: 5
});

const Text = styled.p({
    width: 150,
    marginRight: 10,
});

const TextInput = styled.input({
    height: 20,
    width: 200,
    fontSize: 16,
    borderRadius: 5,
    alignSelf: 'center'
});

const Button = styled.button({
    height: 30,
    width: 150,
    borderRadius: 10,
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

export default Login;