
import { log } from "./Utils";
import { getColor } from "./Utils";

import Fetcher from "./Fetcher";
import Globals from "../Globals";
import { toBePartiallyChecked } from "@testing-library/jest-dom/matchers";

const fetcher = new Fetcher();

const pieChartJsonElement = {
    id: "java",
    label: "java",
    value: 285,
    color: "hsl(324, 70%, 50%)"
}

const logg = (message) => {
    log("DataManager", message);
}

class DataManager {

    transformToPieChartData(apiData) {
        let pieChartArr = [];
        let idCnt = 0;
        logg("Traversing Lists to make pieChart array");
        apiData.forEach(list => {
            let element = {id: "", label: "", value: 0, color: ""};
            if (list.title) {
                element.id = idCnt;
                element.label = list.title;
                element.value = idCnt;
                element.color = getColor(idCnt++);
            }
            pieChartArr.push(element);
        });
        return pieChartArr;
    }

    /**
     * Uses and edp list object retrieves userData for the list and checks how many users are
     * logged in, logged out or did neither.
     * @param {a list object} list 
     * @returns JSON element containing user checkin information which can be rendered in a nivo pieChart
     */
    async getListData(list) {
        let pieChartElement = {
            title: "ListName",
            permid: "",
            clickable: false,
            listData: [
                {
                    "id": "anwesend",
                    "label": "anwesend",
                    "value": 0,
                },
                {
                    "id": "abwesend",
                    "label": "abwesend",
                    "value": 0,
                },
                {
                    "id": "noshow",
                    "label": "noshow",
                    "value": 0,
                }
            ]
        };

        let checkinData = await fetcher.getUserCheckins(list.permid);

        if (checkinData) {
            let data = checkinData.data;
            let dates = checkinData.dates;
            logg("List "+list.title+" - with "+list.roomAppointments.length+" appointments and "+data.length+" registered users");

            let in_cnt = 0;
            let out_cnt = 0;
            let noshow_cnt = 0;
            data.forEach((user) => {
                if (user.latestCheckIn) {
                    in_cnt++;
                }
                if (user.latestCheckOut) {
                    out_cnt++;
                }
                if (!user.latestCheckOut && !user.latestCheckIn) {
                    noshow_cnt++;
                }
            });

            pieChartElement.title = list.title;
            pieChartElement.permid = list.permid;
            pieChartElement.clickable = list.roomAppointments && list.roomAppointments.length > 0;
            pieChartElement.listData[0].value = in_cnt;
            pieChartElement.listData[1].value = out_cnt;
            pieChartElement.listData[2].value = noshow_cnt;

            return pieChartElement;
        }
    }

    /**
     * Retrieve List appointment data and userData for that list
     * @param {the permid from the list} permid 
     * @returns an Array wiht [0]-listdata and [1]-userdata for that list
     */
    async getSavedListAndUserData(permid) {
        let listDataString = sessionStorage.getItem(Globals.SESSION_STORAGE_LISTS);
        let userDataString = sessionStorage.getItem(Globals.SESSION_STORAGE_USERS+permid);

        if (!listDataString) {
            logg("No listData saved in sessionStorage!");
            return [];
        }

        if (!userDataString) {
            logg("No userData for permid "+permid+" saved in sessionStorage, retrieving new ones");
            let userData = await fetcher.getUserCheckins(permid);
            if (!userData) {
                logg("Could not retrieve userData for List");
                return [];
            }
            userDataString = JSON.stringify(userData);
        }

        let listData = null;
        let userData = null;

        try {
            listData = JSON.parse(listDataString);
            userData = JSON.parse(userDataString);

            let roomAppoint = [];

            if (!roomAppoint) {
                logg("Could not find list with permid: "+permid);
                return [];
            }
    
            return [{fuckyou: "Deine mutter"}];
    
        }catch(err) {
            logg("ERROR, could not parse sessionStorage lists/users: "+JSON.stringify(err));
            return [];
        }


    }

    async getSavedLists() {
        let listDataString = sessionStorage.getItem(Globals.SESSION_STORAGE_LISTS);

        if (!listDataString) {
            logg("No list data saved in sessionStorage, retrieving from API now");
            let listData = await fetcher.getLists();

            if (!listData) {
                logg("Could not retrieve listData, aborting");
                return null;
            }

            //Filter out old/archived lists
            listData = listData.filter(list => list.description.archived == null || list.description.archived == false);

            return listData;
        }

        try {
            let listData = JSON.parse(listDataString);
            return listData;
        }catch(err) {
            logg("Error during parsing listData string: "+JSON.stringify(err));
            return null;
        }
    }

    async getSavedUsers(permid) {
        let userDataString = sessionStorage.getItem(Globals.SESSION_STORAGE_USERS+permid);

        if (!userDataString) {
            logg("No userData for permid "+permid+" saved in sessionStorage, retrieving from API now");
            let userData = await fetcher.getUserCheckins(permid);

            if (!userData) {
                logg("Could not retrieve userData for List");
                return null;
            }

            return userData;
        }

        try {
            let userData = JSON.parse(userDataString);
            return userData;
        }catch(err) {
            logg("Error during parsing userData string: "+JSON.stringify(err));
            return null;
        }
    }


    /**
     * Converts a Lists appointment data combined with user checkin data.
     * Creates an array of checkin information for every appointment this list has
     * @param {permid of list} permid 
     * @returns an array of user checkin info for every appointment in the list
     */
    getAppointmentData(appoint, userData) {
        /*
        let listData = await this.getSavedLists();

        if (!listData) {
            return [];
        }

        listData = listData.find(list => list.permid === permid);

        let userData = await this.getSavedUsers(permid);

        if (!userData || !userData.data) {
            return [];
        }

        //The acutal userdata array
        userData = userData.data;

        logg("UserData: "+ JSON.stringify(userData));

        let appointments = listData.roomAppointments;
        let pieChartArr = [];
        */

        //appointments.forEach((appoint) => {



            let pieChartElement = {
                title: "ListName",
                permid: "",
                clickable: false,
                listData: [
                    {
                        "id": "anwesend",
                        "label": "anwesend",
                        "value": 0,
                    },
                    {
                        "id": "abwesend",
                        "label": "abwesend",
                        "value": 0,
                    }
                ]
            };

            if (!appoint.description || !appoint.description.name || !appoint.description.appointment || !appoint.description.appointment.startDate || !appoint.permid) {
                logg("Missing fields in appointment, aborting");
                //return null;
            }

            let appointName = appoint.title.substring(5);
            let appointPermId = appoint.permid;
            let appointDateTime = appoint.description.appointment.startDate.split(" ");
            if (appointDateTime.length !== 2) {
                logg("Appointment startDate is broken: "+appoint.appointment.startDate);
                //return null;
            }
            let appointDate = appointDateTime[0];
            let appointTime = appointDateTime[1];

            logg("Appointment: "+appointName+" with permid: "+appointPermId+".\t Starting at "+appointDate);

            let users_in = 0;
            let users_out = 0;

            userData.forEach((user) => {
                if (!user.assignments) {
                    //logg("User "+user.fullname+" has no assignments");
                    return;
                }

                //Check if user has current appointment
                if (user.assignments.find((id) => id === appointPermId.substring(1))) {
                    //logg("User has current assignment: "+user.fullname);

                    let inFound = false;

                    user.logs.forEach((log) => {
                        let split = log.date.split(" ");
                        if (split.length !== 2) {
                            logg("User log date is broken: "+log.date)
                            return;
                        }

                        let date = split[0];
                        let uDate = date.split("-");
                        let uyear = uDate[0], umonth = uDate[1], uday = uDate[2]

                        let aDate = appointDate.split(".");
                        let aday = aDate[0], amonth = aDate[1], ayear = aDate[2];

                        let time = split[1];


                        if (uyear === ayear && umonth === amonth && uday === aday) {
                            let type = log.type;
                            if (type = "IN") {
                                //logg("Found matching date with IN for user: "+user.fullname);
                                inFound = true;
                                return;
                            }
                        }

                        //return;
                    });

                    if (inFound) {
                        users_in++;
                    }else {
                        users_out++;
                    }
                }else {
                    //logg("User doesnt have current assignment: "+user.fullname);
                }
            });

            logg("Traversed users, users_in: "+users_in+"\t users_out: "+users_out);

            pieChartElement.listData[0].value = users_in;
            pieChartElement.listData[1].value = users_out;
            pieChartElement.title = appointName;
            pieChartElement.permid = appointPermId;

            return pieChartElement;

            //pieChartArr.push(pieChartElement);
        //});

        
        //return pieChartArr;
    }
}

export default DataManager;