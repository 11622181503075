//Public packages
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PieChart from '../components/PieChart';
import styled from 'styled-components';

//Own Classes / Components
import '../App.css';
import Header from "../components/Header";
import Fetcher from '../util/Fetcher';
import DataManager from '../util/DataManager';
import { log } from '../util/Utils';
import Login from "../components/Login";
import Globals from '../Globals';
import LoadingSpinner from '../components/LoadingSpinner';

const fetcher = new Fetcher();
const dataManager = new DataManager();

const logg = (message) => {
    log("UserLists", message);
}

const UserLists = () => {
    const [data, setData] = useState([]);
    const [loginDone, setLoginDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingText] = useState("Listen werden geladen");
    const [devMode] = useState(true);
    const [queryParams, setQueryParams] = useState(null);

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => {
        if (loginDone) {
            if (!fetcher.checkToken(devMode)) {
                logg("No cookie saved")
                return;
            }

            checkSessionStorage();
        }
    }, []);

    /**
     * Checks the sessionStorage for saved renderData.
     * If found it gets set if not it gets reloaded
     */
    const checkSessionStorage = () => {
        let qPar = new URLSearchParams(loc.search);
        setQueryParams(qPar);
        const session = qPar.get("session");

        if (!session) {
            logg("Dont use session data and reload every time");
            getListData();
            return;
        }

        let savedRenderData = sessionStorage.getItem(Globals.SESSION_STORAGE_RENDER_LISTS);
        if (savedRenderData) {
            try {
                let savedRenderDataJson = JSON.parse(savedRenderData);
                logg("Found list renderData in sessionStorage");
                setData(savedRenderDataJson);
            }catch(err) {
                logg("ERROR during parsing saved render data: "+JSON.stringify(err));
                getListData();
            }
        }else {
            logg("No list renderData in sessionStorage, reloading now");
            getListData();
        }   
    }
    
    const getListData = async () => {
        setLoading(true);
        
        let allLists = await fetcher.getLists();

        if (allLists) {
            //Filter out old or deactivated lists
            allLists = allLists.filter(list => list.description.archived == null || list.description.archived == false);
            allLists.forEach(async (list) => {
                let listData = await dataManager.getListData(list);
                
                let stillLoading = data.length === allLists.length-1; 
                data.push(listData);
                setData(data);
                setLoading(!stillLoading)

                sessionStorage.setItem(Globals.SESSION_STORAGE_RENDER_LISTS, JSON.stringify(data));
            });
        }
    }

    const listClicked = async (permid) => {
        logg("List with permid: "+permid+" clicked, loading appointment data");
        let listTitle = data.find(i => i.permid === permid).title;
        let qPar = new URLSearchParams(loc.search);
        const sessionPar = qPar.get("session");
        navigate(Globals.SCREEN_LIST_DAY_APPOINTMENTS, {state: {permid: permid, title: listTitle, session: sessionPar}});
    }

    return (
        <div className="App">
            <MainContainer>
                <Header />
                {loading ? <LoadingSpinner loadingText={loadingText} /> : null }

                {loginDone ?
                    <ChartContainer>
                        {data.map((list, index) => {
                            return <PieChart
                                data={list}
                                onClick={(permid) => {listClicked(permid)}}
                                key={index}
                            />
                        })}
                    </ChartContainer>
                    :
                    <Login 
                        loginDone={() => {
                            setLoginDone(true);
                            getListData();
                        }}
                    />
                }
            </MainContainer>
        </div>
    );
}

const MainContainer = styled.div({
    display: 'flex',
    height: '100vh',
    width: '100vw',
    flexDirection: 'column',
    
});

const ChartContainer =styled.div({
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'auto auto auto',
    overflowY: 'scroll',
    columnGap: 20,
    rowGap: 20,
});

export default UserLists;