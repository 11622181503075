import React from "react";
import styled from 'styled-components';


import PieChart from './components/PieChart';
import './App.css';
import Header from "./components/Header";
import Fetcher from './util/Fetcher';
import DataManager from './util/DataManager';
import { log } from './util/Utils';
import Login from "./components/Login";

import loadingSpinner from "./assets/img/loadingSpinner.svg"

const fetcher = new Fetcher();
const dataManager = new DataManager();

const logg = (message) => {
    log("App2", message);
}

class App2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            loadingText: "Listen werden geladen",
            loginDone: true,
            devMode: true,
        };
    }
    
    componentDidMount() {
        if (this.state.loginDone) {
            if (!fetcher.checkToken(this.state.devMode)) {
                logg("No cookie saved")
                return;
            }

            this.getListData();
        }
    }

    render() {
        return(
            <div className="App">
                <Header />
                <MainContainer>
                    {this.state.loading ?
                        <div style={{position: 'absolute', zIndex: 2, top: '50%', left: 'auto', textAlign: 'center'}}>
                            <img src={loadingSpinner} />
                            <p style={{marginTop: -20, fontSize: 25, fontWeight: 200}}>{this.state.loadingText}</p>
                        </div>
                        : null
                    }
                    {this.state.loginDone ?
                        <ChartContainer>
                            {
                            this.state.data.map((list, index) => {
                                return <PieChart
                                    data={list}
                                    key={index}
                                    onClick={(permid) => {this.listClicked(permid)}}
                                />
                            })
                            }
                        </ChartContainer>
                        :
                        <Login loginDone={(done) => {this.setState({loginDone: true}); this.getListData(); }} />
                    }
                </MainContainer>
            </div>
        );
    }

    async listClicked(permid) {
        logg("List with permid: "+permid+" clicked");

        /*
        this.setState({loadingText: "Termine werden geladen", loading: true});
        let renderData = await dataManager.getDayAppointments();
        this.setState({data: renderData, loading: false})
        */
    }

    async getListData() {
        this.setState({loading: true});
        let allLists = await fetcher.getLists();
        
        if (allLists) {
            //Filter out old or deactivated lists
            allLists = allLists.filter(list => list.description.archived == null || list.description.archived == false);
            allLists.forEach(async (list) => {
                let listData = await dataManager.getListData(list);
                
                let stillLoading = this.state.data.length === allLists.length-1; 
                this.state.data.push(listData);
                this.setState({data: this.state.data, loading: !stillLoading});
            });
        }
    }
}

const MainContainer = styled.div({
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
});

const ChartContainer =styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    padding: 10,
    overflowY: 'scroll'
});

export default App2;