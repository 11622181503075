import axios from "axios"
import Cookies from 'js-cookie';

import Globals from "../Globals";
import { log } from "./Utils";

import packageJson from "../../package.json"

const apiUrl = "https://ub-prod.mwsmag.de/pa-api";
const apiUrl2 = "https://726c-213-216-22-156.ngrok-free.app";
const apiVersion = "/v17";
const baseUrl = apiUrl+apiVersion;

//

const logg = (message) => {
    log("Fetcher", message);
}

const sleep = (waitTimeInMs) => new Promise(resolve => setTimeout(resolve, waitTimeInMs));

class Fetcher {


    /**
     * Sends a post request to the specified URL
     * @param {data to send in post} formData 
     * @param {url to post to} url 
     * @returns the response data or null if failed
     */
    async makePost(url, formData) {
        try {
            //let config = {headers: {"User-Agent": "pa-dashboard/v"+packageJson.version}};
            //let resp = await axios.post(url+"?trick17=true", formData, config);

            let resp = await axios.post(url, formData);

            if (resp.data.success) {
                return resp.data;
            }else {
                logg("Not successfull: "+resp.data.message)
                return null;
            }
        }catch(err) {
            logg("Error occurred during post:\n"+JSON.stringify(err))
            return null;
        }
    }

    /**
     * Sends a verify code to either the phone number or email adress of the user
     * @param {the email or phone number of the user} loginText 
     * @returns true if verify code was sent
     */
    async authUser(loginText) {
        const formData = new FormData();
        formData.append('username', loginText);
      
        try {
            let resp = await axios.post(baseUrl+'/auth', formData);

            if (resp.data.success) {
                logg("Sent verify info to "+loginText);
                return true;
            }else {
                logg("Cannot sent verify info to "+loginText)
                return false;
            }
        }catch(err) {
            logg("Error occurred during send verify info:\n"+JSON.stringify(err))
            return false;
        }
    }

    /**
     * Checks if the entered verify code is correct. 
     * @param {the email or phone number of the uer} loginText 
     * @param {the 6 digits code sent to either email or number} verifyInfo 
     * @returns the authentication token if code was correct or null if not
     */
    async verifyUser(loginText, verifyInfo) {
        const formData = new FormData();
        formData.append('username', loginText);
        formData.append('code', verifyInfo);
      
        try {
            let resp = await axios.post(baseUrl+'/auth/verify', formData);
            
            if (resp.data.success) {
                logg("Authentication successfull for "+loginText);
                return resp.data.token;
            }else {
                logg("Verify token got rejected "+verifyInfo);
                return null;
            }
        }catch(err) {
            logg("Error occurred during send verify info:\n"+JSON.stringify(err))
            return null;
        }
    }

    async refreshToken(oldToken) {
        const formData = new FormData();
        formData.append('token', oldToken);
      
        try {
            let resp = await axios.post(baseUrl+'/auth/refresh', formData);
            
            if (resp.data.success) {
                logg("Token successfully renewed");
                return resp.data.token;
            }else {
                logg("Cannot renew token")
                return null;
            }
        }catch(err) {
            logg("Error occurred during token refresh:\n"+JSON.stringify(err))
            return null;
        }
    }

    async refereshBackupToken() {
        const TMP_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJVQi1PVFAiLCJpYXQiOjE3MzI3MTA2MzAsImV4cCI6MTczMjk3MDEzMCwia2V5Ijoib21hIiwidXNlcl9pZCI6IjI5OWI3MjU5LTQyYjYtMTFlZi1hODkyLTA2ZjBjNjY0NzgzNiIsImVtYWlsIjoia3NsMS50ZXN0c3R1ZGVudEBzdHVkZW50cy51bmliZS5jaCIsIm1vZGUiOiJ2aXNpdG9yIn0.npMXvHCs8MSq4wvZd5AfqAYwDtC_s9b9odzgZrDob7k";
        let newToken = await this.refreshToken(TMP_TOKEN);
        Cookies.set(Globals.COOKIE_TOKEN_NAME, newToken, {expires: 5, path: '', sameSite: 'strict'});
    }

    /**
     * Tries to retrieve a cookie from local storage and checks if it is expired.
     * If it is expired, it is renewed and saved back to local storage.
     * If no cookie is presend nothing is done.
     */
    async checkToken(devMode) {
        if (devMode) {
            this.refereshBackupToken();
            return;
        }

        let cookie = this.getCookie(Globals.COOKIE_TOKEN_NAME);

        if (!cookie) {
            logg("No auth token is saved!");
            return false;
        }

        try {
            //JWT tokens are xyz.xyz.xyz we want the middle part
            let jwtJson = cookie.split(".")[1];
            let tokenString = atob(jwtJson);
            let tokenJSON = JSON.parse(tokenString);
            let expMs = tokenJSON.exp * 1000;

            if (expMs > Date.now()) {
                logg("Token is valid");
                return true;
            }else {
                logg("Token expired, refreshing now");
                let newToken = await this.refreshToken(cookie);
                Cookies.set(Globals.COOKIE_TOKEN_NAME, newToken, {expires: Globals.COOKIE_TOKEN_EXP_DURATION, path: '', sameSite: 'strict'});
                return true;
            }
        }catch(err) {
            logg("Error parsing JWT cookie:\n"+JSON.stringify(err));
            return false;
        }
    }

    /**
     * Searches through the cookies to find searchKey
     * @param {to search through cookies} searchKey 
     * @returns the cookies value or null if not found
     */
    getCookie(searchKey) {
        let cookies = document.cookie.split(";");
        let value = null;

        //No cookies
        if (cookies.length === 0) {
            logg("No cookies present: "+cookies);
            return null;
        }

        cookies.forEach( (cookie) => {
            let keyVal = cookie.split("=");

            if (keyVal.length < 2) {
                logg("cookie broken: "+cookie);
            }

            let key = keyVal[0];

            if (key.includes(searchKey)) {
                value = keyVal[1];
                return;
            }
        });
        return value;
    }

    async getLists() {
        let token = this.getCookie(Globals.COOKIE_TOKEN_NAME);
        if (token) {
            const formData = new FormData();
            formData.append('token', token);
            let data = await this.makePost(baseUrl+"/lists/dashboard", formData);

            if (!data) {
                logg("Cannot retrieve lists");
                return null;
            }

            logg("Lists retrieved successfully"+JSON.stringify(data.data));
            sessionStorage.setItem(Globals.SESSION_STORAGE_LISTS, JSON.stringify(data.data));
            return data.data;

        }else {
            logg("No auth token present, cannot retrieve any data");
            return null;
        }
    }

    async getUserCheckins(permid) {
        let token = this.getCookie(Globals.COOKIE_TOKEN_NAME);
        if (token) {
            const formData = new FormData();
            formData.append('token', token);
            formData.append('id', permid.substring(1));
            let data = await this.makePost(baseUrl+"/users", formData);

            if (!data) {
                logg("Cannot retrieve user checkins");
                return null;
            }

            logg("User checkins retrieved successfully");
            //sessionStorage.setItem(Globals.SESSION_STORAGE_USERS+permid, JSON.stringify(data));
            return data;

        }else {
            logg("No auth token present, cannot retrieve any data");
            return null;
        }
    }
}

export default Fetcher;